import {createActionGroup, emptyProps, props} from "@ngrx/store";
import {LoadItem} from "@models/loads.model";
import {TransportPermitDetail} from "@models/transport-permit.model";

export const getPermitDetailsAction = createActionGroup({
  source: 'getLoadByRegNumber',
  events: {
    'Request Permit Details': props<{ regNumber: string, date: string, siteId: number }>(),
    'Request Permit Details Success': props<{ permitDetail: TransportPermitDetail }>(),
    'Request Permit Details Failed': (error: string) => ({ error }),
    'Request Permit Details Clear State': emptyProps(),
  },
})
