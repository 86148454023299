import { Injectable } from '@angular/core';
import {map, Observable, of, throwError} from "rxjs";
import {LoadItem} from "@models/loads.model";
import {environment} from "@environment/environment";
import {HttpClient} from "@angular/common/http";
import {TransportPermitDetail, TransportPermitListAPIResponse} from "@models/transport-permit.model";
import {ManualEntryCreateDto, ManualExitCreateDto} from "@module/load-management/models/manual-loads.models";

@Injectable({
  providedIn: 'root'
})
export class ManualLoadsService {

  constructor(private http: HttpClient) { }

  getPermitDetail(regNumber: string, date: string, siteId: number): Observable<TransportPermitDetail> {
    return this.http
      .get<TransportPermitListAPIResponse>(
        `${environment.apiBaseUrl}/transport-permits?dateFrom=${date}&siteId=${siteId}&horseReg=${regNumber}`
      )
      .pipe(
        map((apiResponse: TransportPermitListAPIResponse) => {
          if(apiResponse.items.length === 0) {
            throwError(() => new Error('No transport permit found.'))

          }
          if(apiResponse.items.length > 1) {
            throwError(() => new Error('Multiple transport permits found.'))
          }
          return apiResponse.items[0];
        }
      ));
  }

  createManualLoad(manualEventCreateDto: ManualEntryCreateDto | ManualExitCreateDto): Observable<LoadItem> {
    return this.http
      .post<LoadItem>(
        `${environment.apiBaseUrl}/manual-loads`, manualEventCreateDto
      )
      .pipe(
        map((result: LoadItem) => result)
      );
  }
}
